
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Multiselect from '@vueform/multiselect';
  import * as Yup from 'yup';
  import { AdsPackageObject } from '@/store/modules/Subscriptions/AdsPackageModule';

  export default defineComponent({
    name: 'ads-packages-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitServicesButton = ref<HTMLButtonElement | null>(null);
      const submitPricesButton = ref<HTMLButtonElement | null>(null);
      const multiselect = ref(null);
      const createdPackage = ref<AdsPackageObject | null>(null);
      const loading = ref(false);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('adsPackagesUpdating'), [
          translate('subscriptions'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_ADS_PACKAGE,
        route.params.id
      );

      const packages = ref<AdsPackageObject>(data.data);
      const selectedServices = ref<Array<number>>(
        data.data.adsServicesPackages.map((a) => a.id)
      );
      const packageSchema = Yup.object().shape({
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Arabic Title'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('English Title'),
        descriptionAr: Yup.string()
          .required(() => translate('DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Arabic Description'),
        descriptionEn: Yup.string()
          .required(() => translate('DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('English Description'),

        price: Yup.string()
          .required(() => translate('PRICE_IS_REQUIRED_FIELD'))
          .label('Ads Package Price'),

        duration: Yup.string()
          .required(() => translate('ADS_DURATION_IS_REQUIRED_FIELD'))
          .label('Ads Duration'),
        sort: Yup.string()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('Sort'),
      });
      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.duration = Number(values.duration);
        values.price = Number(values.price);
        values.sort = Number(values.sort);
        await store.dispatch(Actions.UPDATE_ADS_PACKAGE, {
          id: packages.value?.id,
          data: values,
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_ADS_PACKAGE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            router.push({ name: 'ad-package-listing' });
          });
        }
      };

      const onSubmitServices = async () => {
        if (submitServicesButton.value) {
          // eslint-disable-next-line
          submitServicesButton.value!.disabled = true;
          submitServicesButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const payload = {
          id: packages.value?.id,
          data: { serviceIds: selectedServices.value },
        };

        await store.dispatch(Actions.UPDATE_ADS_PACKAGE, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitServicesButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitServicesButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_ADS_PACKAGE_SERVICES'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitServicesButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitServicesButton.value!.disabled = false;
          });
        }
      };

      await store.dispatch(Actions.GET_SERVICE_LIST, { limit: 50 });
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        services: computed(() => store.getters.adsServicesList),
        packages,
        submitServicesButton,
        submitPricesButton,
        onSubmitServices,
        onSubmitUpdate,
        submitButton,
        translate,
        packageSchema,
        goBack,
        can,
        selectedServices,
        multiSelectLabel: 'title',
        loading,
        multiselect,
        createdPackage,
      };
    },
  });
